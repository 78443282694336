import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from 'src/services/contentful.service';
import { ACTIVATION_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS, SHOP_ROUTE_URLS, SUPPORT_ROUTE_URLS } from '../app.routes.names';
import { Observable, Subscription, of } from 'rxjs';
import { AccountPaymentService, IAutoCompletePrediction, IDeviceCompatibilityV1, IExistingOrder, IFirebaseAddress, IUser, IUserPlan, MobileCustomPlansService, PlacesAutocompleteService } from '@ztarmobile/zwp-service-backend';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AppState } from '../app.service';
import { EquipmentService } from '@ztarmobile/zwp-service-backend-v2';
import { ModalHelper } from 'src/services/modal-helper.service';
import { ToastrHelperService } from 'src/services/toast-helper.service';
import { ATT, CAPTCHA_BOT_ERROR_CODE, PARAM_ERROR_MESSAGES, TMO } from '../app.config';
import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-bring-phone',
  templateUrl: './bring-phone.component.html',
  styleUrls: ['./bring-phone.component.scss'],
  animations: [fadeSlide(300)]
})

export class BringPhoneComponent implements OnInit, OnDestroy {
  public compatibilityForm: FormGroup;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public firstThreeQuestions;
  public restQuestions;
  public questionId: string;
  public collapsed = false;
  public showMore = false;
  public displayedAddressModel: any;
  public invalidAddress = false;
  public filteredOptions: Observable<Array<IAutoCompletePrediction>>;
  public address: any;
  public equipment = '';
  public zipCode = '';
  public userPlanId: string;
  public user: IUser;
  public withDevice = false;
  public captchaResponse: string;
  public compatibleDevice: IDeviceCompatibilityV1;
  public userPlan: IUserPlan;
  public simValidity: IExistingOrder;
  public calculatedHeight;
  public defaultSIM;
  public showCompatibilityform = false;
  public showGetStarted = true;
  public recaptchaResolvedSubscription: Subscription;
  
  private streetSearchText: string;

  constructor(
    public router: Router,
    private contentfulService: ContentfulService,
    private placesAutoCompleteService: PlacesAutocompleteService,
    private formBuilder: FormBuilder,
    private appState: AppState,
    private equipmentService: EquipmentService,
    public modalHelper: ModalHelper,
    public toastHelper: ToastrHelperService,
    public mobileCustomPlansService: MobileCustomPlansService,
    public cdr: ChangeDetectorRef) {

    this.compatibilityForm = this.formBuilder.group({
      imei: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(18)])],
      address: ['', Validators.required, this.checkAddress()]
    });
  }

  ngOnInit(): void {
    this.contentfulService.getQuestionsByCategoryId('faqs', 'byod').subscribe(questions => {
      if (!!questions) {
        const allQuestions = questions[0].fields.questions;
        this.firstThreeQuestions = allQuestions.slice(0, 3);
        this.restQuestions = allQuestions.slice(3);
      }
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }
  public checkAddress(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const addressValue = control.value;
      if (!addressValue) {
        return of(null);
      }
      const addressInvalid = !this.displayedAddressModel && !!addressValue;
      return of(addressInvalid ? { invalidAddress: true } : null);
    }
  }

  public onPaste(event: any): void {
    event.preventDefault();
  }

  public showCheckCompatibility() {
    this.showCompatibilityform = true;
    this.showGetStarted = false;
    this.cdr.detectChanges();
  }

  public hideCheckCompatibility() {
    this.compatibilityForm.reset();
    this.showCompatibilityform = false;
    setTimeout(() => {
      this.showGetStarted = true;
    }, 300)
    this.cdr.detectChanges();
  }

  public changedAddress(): void {
    this.findPlace(this.compatibilityForm.controls.address.value);
    this.displayedAddressModel = null;
  }

  public findPlace(keyword: ''): Observable<Array<IAutoCompletePrediction>> {
    this.filteredOptions = this.placesAutoCompleteService.findAddress(keyword);
    return this.filteredOptions;
  }

  public checkYourPhone(): void {
    this.router.navigate([`${ACTIVATION_ROUTE_URLS.BASE}/${ACTIVATION_ROUTE_URLS.CHECK_PHONE}`]);
  }

  public goToPlans(month?: number): void {
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`], {
      queryParams: { month }
    });
  }

  public toggleActive(targetId, answerId): void {
    if (this.questionId === targetId && !this.collapsed) {
      this.questionId = 'q0';
    } else {
      this.questionId = targetId;
      this.collapsed = false;
      this.callRichText(answerId);
    }
  }

  public populatePhoneDetails(phoneDetails) {
    this.compatibleDevice = phoneDetails.details as IDeviceCompatibilityV1;
    this.compatibleDevice.manufacturer = phoneDetails?.details?.make;
    this.compatibleDevice.marketingName = phoneDetails?.details?.name;
    this.compatibleDevice.address1 = this.displayedAddressModel?.address1;
    this.compatibleDevice.address2 = this.displayedAddressModel?.address2;
    this.compatibleDevice.city = this.displayedAddressModel?.city;
    this.compatibleDevice.state = this.displayedAddressModel?.state;
    this.compatibleDevice.postalCode = this.displayedAddressModel?.postalCode;
    this.compatibleDevice.id = phoneDetails?.details?.serialNumber;
  }

  public handleCompatibilityError(error) {
    this.appState.loading = false;
    this.recaptchaResolvedSubscription?.unsubscribe();
    const errorDetails = error?.error?.errors[0];
    let errorMessage = errorDetails?.message;
    
    if(errorDetails?.code !== CAPTCHA_BOT_ERROR_CODE) {    
      if (errorDetails?.code === 102) {
        const paramName = errorDetails.params[0]?.param_name;
        const paramMessage = errorDetails.params[0]?.message;
  
        if (paramName.includes('equipment')) {
          errorMessage = PARAM_ERROR_MESSAGES.IMEI;
        } else if (paramMessage === 'Invalid zip code') {
          errorMessage = PARAM_ERROR_MESSAGES.ADDRESS;
        }
      }
  
      errorMessage = errorMessage || errorDetails?.message || PARAM_ERROR_MESSAGES.GENERIC;
  
      const customHTML = `<div class="message" >
          <p>${errorMessage}</p>
        </div>`;
    this.modalHelper.showInformationMessageModal('OOPS!', '', 'Try Again', null, true, 'check-compatibility-modal', customHTML, undefined, undefined, undefined, undefined, { top: '250px' });
    } else if(errorDetails?.code === CAPTCHA_BOT_ERROR_CODE) {
      this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result) => {
        if (!!result) {
          this.appState.loading = true;
          this.callCheckCoverageApi(result, false);
        }
      });
     }
  }
  public addressDetails(eventFire: IAutoCompletePrediction): void {
    if (!!eventFire && !!this.compatibilityForm.controls.address?.value && this.compatibilityForm.controls.address?.value?.main_text) {
      const event = this.compatibilityForm.controls.address?.value;
      if (!!event.place_id) {
        this.appState.loading = true;
        this.invalidAddress = false;
        //this is a default value until address have the value from api
        this.address = event?.main_text;
        this.placesAutoCompleteService
          .findDetailedAddressFields(event.place_id)
          .subscribe(
            (place) => {
              this.streetSearchText =
                !!place.address1 && place.address1.length > 0
                  ? place.address1
                  : null;
              this.displayedAddressModel = this.getAddressValues(
                place,
                event.main_text
              );
              this.address = `${this.displayedAddressModel?.address1}, ${this.displayedAddressModel?.city
                }, ${this.displayedAddressModel?.state} ${this.displayedAddressModel?.postalCode
                  ? this.displayedAddressModel?.postalCode
                  : ''
                }`;
              this.compatibilityForm.controls.address.setValue(this.address);
              this.appState.loading = false;
            },
            (error) => {
              this.appState.loading = false;
              console.warn(
                `Google can't find details for place: ${event.place_id}`,
                error
              );
            }
          );
      } else {
        this.invalidAddress = true;
        console.warn(`Google can't find place: ${event.main_text}`);
      }
    } else {
      this.invalidAddress = true;
    }
  }

  public checkPhoneCompatibility(): void {
    this.compatibilityForm.markAllAsTouched();
    const action = 'freeup_check_coverage_by_imei';
    if (!!this.compatibilityForm.valid && !!this.displayedAddressModel) {
      this.appState.loading = true;
      this.appState.resetAndExecuteCaptchaSubject.next({action});
      this.recaptchaResolvedSubscription= this.appState.resolvedCaptcha.subscribe(response => {
        this.captchaResponse = response;
        if(!!this.captchaResponse) {
          this.recaptchaResolvedSubscription?.unsubscribe();
          this.equipment = this.compatibilityForm.controls.imei.value;
          if (this.equipment.indexOf(' ') >= 0) {
            this.equipment = this.equipment.replace(/\s+/g, '');
          }
          this.zipCode = this.displayedAddressModel?.postalCode;
          this.callCheckCoverageApi(this.captchaResponse, true, action);
        }
      });
    }
  }
  public callCheckCoverageApi(captchaRes, invisibleCaptchaRes, action?): void {
    this.equipmentService.checkNetworkCoverageByImei(captchaRes, this.displayedAddressModel?.postalCode,
      this.displayedAddressModel?.address1, this.displayedAddressModel?.city,
      this.displayedAddressModel?.state,this.equipment,this.displayedAddressModel?.address2, null,!!action?action:null, invisibleCaptchaRes).then(res => {
        if (!!res) {
          this.appState.loading = false;
          this.defaultSIM = this.getDefaultSIM(res);
          if (!!this.defaultSIM) { // covered
            this.populatePhoneDetails(this.defaultSIM);
            const customHTML = `<div class="message">
              <p>Your device is ready and able to join our network!</p>
              <p>Make sure your device is unlocked to work on the FreeUp Mobile Network.</p>
            </div>`;
            this.modalHelper.showInformationMessageModal('Congratulations!', '', 'Shop Plans', null, true, 'check-compatibility-modal', customHTML, undefined, undefined, undefined, undefined, { top: '250px' })
              .afterClosed().subscribe(res => {
                this.mobileCustomPlansService.setPlanDevice(this.compatibleDevice);
                const isESIM = !!this.defaultSIM.details.eSimCapable;
                this.mobileCustomPlansService.seteSIM(isESIM);
                if (res)
                  this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`]);
              });
          }
        }
      }, (error) => {
        this.handleCompatibilityError(error);
      }).catch((error)=>{
        this.handleCompatibilityError(error);
      })
  }
  public getDefaultSIM(res) {
    let defaultSIM;
    if (!!res?.equipmentCoverage?.defaultSim) {
      defaultSIM = res.equipmentCoverage.defaultSim;
    } else {
      const attCovered = res?.equipmentCoverage?.simOptions.some((i) => i.network === ATT);
      const esimCapable = res?.equipmentCoverage?.simOptions[0].details.eSimCapable; // we need to check if we have to default to esim or physical sim based on the device
      if (!!attCovered) {
        const attOption = res?.equipmentCoverage?.simOptions.find((i) => i.network === ATT && (!!esimCapable ? i.simType === 'esim' : i.simType === 'sim'));
        defaultSIM = attOption;
      } else {
        const tmoOption = res?.equipmentCoverage?.simOptions.find((i) => i.network === TMO && (!!esimCapable ? i.simType === 'esim' : i.simType === 'sim'));
        defaultSIM = tmoOption;
      }
    }
    return defaultSIM
  }

  private callRichText(id): void {
    this.contentfulService.getRichText('questions', id);
  }

  private getAddressValues(
    placeInfo: any,
    searchTerms?: string
  ): IFirebaseAddress {
    let address: IFirebaseAddress = {
      name: !!this.displayedAddressModel?.name
        ? this.displayedAddressModel?.name
        : this.displayedAddressModel?.alias,
      address1: placeInfo.address1,
      address2: placeInfo.address2,
      state: placeInfo.state ? placeInfo.state.toUpperCase() : placeInfo.state,
      city: placeInfo.city,
      country: placeInfo.country || 'United States',
      postalCode: placeInfo.postalCode,
    } as IFirebaseAddress;

    if (!!this.displayedAddressModel?.id) {
      address.id = this.displayedAddressModel?.id;
      address.isDefault = this.displayedAddressModel?.isDefault;
    }
    if (!!searchTerms && typeof searchTerms === 'string') {
      if (!!this.streetSearchText) {
        if (!searchTerms.match(this.streetSearchText)) {
          this.streetSearchText = null;
        }
      }
      address.address1 = !!this.streetSearchText
        ? address.address1
        : searchTerms.trim();
    }
    if (!!address && address.address1)
      address.address1 = AccountPaymentService.shortenAddress(address.address1, 30);

    // Clean Out empty values,
    address = this.appState.removeEmptyValues(address);
    return Object.assign({}, address);
  }
}

export function fadeSlide(timing: number): AnimationTriggerMetadata {
  return trigger('fadeSlide', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(-100%)' }),
      animate(
        timing,
        style({ opacity: 1, transform: 'translateY(0)' })
      )
    ]),
    transition(':leave', [
      style({ opacity: 1, transform: 'translateY(0)' }),
      animate(
        timing,
        style({ opacity: 0, transform: 'translateX(100%)' })
      )
    ])
  ]);
}
